<template>
  <div>
    <!--供电状态-->
    <el-row>
      <el-col :span="4">
        <div class="grid-content bg-purple" style="ext-align:right;">供电状态:</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-light">{{power}}</div>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <!--自动断电时间-->
    <el-row>
      <el-col :span="4">
        <div class="grid-content bg-purple">自动断电时间:</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-light">{{powerOffTime}}</div>
      </el-col>
    </el-row>
    <el-divider></el-divider>

    <!--设置自动断电-->
    <div style="margin-top:20px">
      <div style="margin:20px 0">设置手工上电后自动断电时间(秒)</div>
      <el-row :gutter="20">
        <el-col :span="9">
          <div class="grid-content bg-purple-dark">
            <el-input size="" placeholder="默认为300" v-model="keepOnSeconds">
              <template slot="prepend">断电时间(秒)</template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="3">
          <el-button type="info" plain @click="modalConfirm">保存设置</el-button>
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div style="margin-top:40px">
      <el-row>
        <el-button style="width:100px" type="success" @click="tabCamPowerOn">上电</el-button>
        <el-button style="width:100px" type="danger" @click="tabCamPowerOff">断电</el-button>
      </el-row>
    </div>

  </div>
</template>

<script>
import basepage from '../../../common/basepage.js';

export default {
  mixins: [basepage],

  data() {
    return {
      devices: [],
      power: '',
      powerOffTime: '',
      keepOnSecondsTitle: '断电时间(秒)',
      keepOnSeconds: '',
    };
  },
  props: {
    uid: {
      type: String,
    }
  },
  methods: {
    //获取配置
    getConfig() {
      let json = {
        "cmd": "deviceConfig",
        "tag": 'camPower',
        "uid": this.uid,
      }
      this.ws.send(json);
    },
    //获取摄像机供电状态
    getCamPowerStatus() {
      let json = {
        "cmd": "pushEvent",
        "tag": 'camPowerStatus',
        "uid": this.uid,
      }
      this.ws.send(json);
    },
    on_deviceConfigAck(res) {
      if (res.uid != this.uid) {
        return;
      }
      let config = this.common.text2Json(res.config)
      this.keepOnSeconds = config.keepOnSeconds ? config.keepOnSeconds : ''
    },
    on_camPowerStatusReady(res) {
      if (res) {
        this.power = res.power == "1" ? "上电" : "断电"
        this.powerOffTime = res.powerOffTime ? res.powerOffTime : ''
      }
    },
    //上电
    tabCamPowerOn() {
      let json = {
        "cmd": "pushEvent",
        "tag": 'camPowerOn',
        "uid": this.uid,
      }
      this.ws.send(json);
    },
    //断电
    tabCamPowerOff() {
      let json = {
        "cmd": "pushEvent",
        "tag": 'camPowerOff',
        "uid": this.uid,
      }
      this.ws.send(json);
    },
    //保存设置
    modalConfirm() {
      let config = "keepOnSeconds=" + this.keepOnSeconds
      let json = {
        "cmd": "editDeviceConfig",
        "tag": 'camPower',
        "config": config,
        "uid": this.uid,
      }
      this.ws.send(json);
    },
  },
  created() {

  },
  mounted() {
    this.on('deviceConfigAck', (res) => {
      this.on_deviceConfigAck(res);
    })
    this.on('camPowerStatusReady', (res) => {
      this.on_camPowerStatusReady(res);
    })
    this.on('camPowerOnAck', (res) => {
      if (res.uid == this.uid) {
        this.$message({
          message: '已上电',
        });
      }
    })
    this.on('camPowerOffAck', (res) => {
      if (res.uid == this.uid) {
        this.$message({
          message: '已断电',
        });
      }
    })

    this.on('editDeviceConfigAck', (res) => {
      if (res.uid == this.uid && res.error == "0") {
        this.$message({
          message: '设置成功',
          type: 'success'
        });
      }
    })
    this.getConfig()
    this.getCamPowerStatus()
  },

}
</script>

<style>
.el-divider--horizontal {
  display: inline-block;
  display: block;
  width: 50%;
  height: 1px;
  margin: 10px 0 !important;
}
</style>